import React, { useState } from "react";

const PromotionselectDropdown = ({ formData, setFormData }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const testOptions = [
    { value: "Breast Cancer Awareness", label: "Brest Cancer Awareness" },
    { value: "Grand Opening Special🌟", label: "Grand Opening Special🌟" },
    { value: " Gift Them Health This Christmas🌟", label: " Gift Them Health This Christmas🌟" },

   



  ];

  const filteredOptions = testOptions.filter((test) =>
    test.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSelect = (test) => {
    setFormData((prev) => {
      const currentRequiredTests = Array.isArray(prev.requiredTests)
        ? prev.requiredTests
        : [];

      const exists = currentRequiredTests.find((t) => t.value === test.value);
      if (exists) {
        return {
          ...prev,
          requiredTests: currentRequiredTests.filter(
            (t) => t.value !== test.value
          ),
        };
      } else {
        return {
          ...prev,
          requiredTests: [...currentRequiredTests, { ...test, fasting: false }],
        };
      }
    });
  };

  const isSelected = (test) => {
    const currentRequiredTests = Array.isArray(formData.requiredTests)
      ? formData.requiredTests
      : [];
    return currentRequiredTests.some((t) => t.value === test.value);
  };

  return (
    <>
      {/* Main Button to trigger the popup */}
      <button
        type="button"
        className="w-full h-[41px] border-[#4ab0b0] border-[1px] rounded-[6px]"
        onClick={() => setIsDropdownOpen(true)}
      >
        {formData.requiredTests?.length > 0
          ? `${formData.requiredTests.length} Tests Selected`
          : "Pick the Required Promotions"}
      </button>

      {isDropdownOpen && (
        <div className="fixed inset-0 z-30 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-[#11485B] md:w-[50%] w-[90%] h-[50%]  p-4 rounded-lg shadow-lg relative">
            <button
              type="button"
              className="absolute top-0 right-2 text-4xl text-gray-500"
              onClick={() => setIsDropdownOpen(false)}
            >
              &times;
            </button>

            <div className="p-4">
              <input
                className="w-full py-2 px-4 border rounded-lg"
                type="text"
                placeholder="Search Tests"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>

            <div className="px-2 h-[70%] overflow-y-auto">
              {filteredOptions.map((test) => (
                <div
                  key={test.value}
                  className="flex items-center p-2 hover:bg-gray-100 hover:text-black"
                >
                  <div className="flex items-center justify-center w-10">
                    <input
                      type="checkbox"
                      checked={isSelected(test)}
                      onChange={() => handleSelect(test)}
                    />
                  </div>
                  <label
                    className="cursor-pointer"
                    onClick={() => handleSelect(test)}
                  >
                    {test.label}
                  </label>
                </div>
              ))}
              {/* <button
            className="w-full p-2 text-center bg-[#4ab0b0] text-white"
            onClick={() => setIsDropdownOpen(false)}
          >
            Done
          </button> */}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PromotionselectDropdown;
